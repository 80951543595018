@import "nlib/config";

.table {
  [data-table-cell] {
    padding-right: $contentDefaultIndent;
    padding-left: $contentDefaultIndent;
  }
  .row {
    border-bottom: 1px solid lighten($uiBorderColor, 4%);
  }
  .head {
    min-height: 3rem;
    padding: $contentDefaultIndent;
  }
  .nameCell {
    flex: 100;
    width: 100px;
    padding-left: $contentDefaultIndent * 2;
    text-align: left;
  }
  .iconCell {
    flex: 40;
    width: 40px;
    text-align: left;
    a {
      color: inherit;
      text-decoration: none;
      button {
        width: 2rem;
        height: 2rem;
        margin-right: $contentDefaultIndent;
        padding: 0;
        color: $uiWhiteColor;
        svg {
          font-size: 1rem;
        }
      }
    }
  }
  .activityCell {
    flex: 100;
    width: 100px;
    padding-right: 0;
    padding-left: 0;
    text-align: left;
  }
}
