@import "nlib/config";

.activityCell {
  .content {
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-left: -$contentDefaultIndent;
    padding-right: $contentDefaultIndent;
    padding-left: $contentDefaultIndent;
    color: inherit;
    text-decoration: none;
    .text {
      width: 100%;
      white-space: nowrap;
    }
    .date {
      color: $uiNeutralColor;
    }
    .tooltip {
      .tooltipClassName {
        right: 0;
        left: auto;
      }
    }
  }
}
