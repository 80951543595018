@import "nlib/config";

.iconCell {
  a {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
  }
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  button {
    min-width: 2rem;
  }
  &.noData {
    color: $uiNeutralColor;
    cursor: default;
    pointer-events: none;
    button {
      border: 1px solid $uiNeutralColor;
      background: none;
      box-shadow: none;
      color: $uiNeutralColor !important;
    }
  }
  .emptyState {
    width: 2rem;
    text-align: center;
  }
}
