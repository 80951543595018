@import "nlib/config";

.summaryPage {
  .pageHeader {
    display: flex;
    align-items: center;
    padding: $contentDefaultIndent * 2 0;
    font-weight: 500;
    .leftBlock {
      min-width: 0;
      margin-right: $contentDefaultIndent * 2;
      .subTitle {
        opacity: 0.5;
        color: $uiDarkColor;
        font-size: 0.8em;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
      .title {
        display: flex;
        align-items: center;
        overflow: hidden;
        font-size: 1.25rem;
        line-height: 1.2;
        white-space: nowrap;
      }
    }
    .rightBlock {
      display: flex;
      flex: auto;
      justify-content: flex-end;
      .button {
        margin-left: $contentDefaultIndent;
        svg {
          margin-right: 0.3em;
          font-size: 1rem;
        }
      }
    }
  }
  .card {
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: $uiBorderRadius;
    background-color: $uiWhiteColor;
    &.preloader,
    &.noDataContent {
      flex: auto;
    }
    .title {
      padding: $contentDefaultIndent * 2 $contentDefaultIndent * 2 0;
      font-size: 1rem;
      cursor: pointer;
      user-select: none;
      &.collapsed {
        padding-bottom: $contentDefaultIndent * 2;
      }
    }
    + .card {
      margin-top: $contentDefaultIndent * 2;
    }
  }
}
